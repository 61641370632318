.settings-avatar-drop-zone {
    display: block;
    background-color: #f0f0f0;
    width: 100%;
    padding: 40px;
    text-align: center;
    color: #838383;
    font-size: x-large;
    line-height: 1.5em;
    border-radius: 4px;
    cursor: pointer;
}
