.droppable-circle-board {
    flex-shrink: 0;
    background-color: #ffffff;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    margin: 20px 20px;
    box-shadow: 2px 2px 1px 1px #e0e0e0;
    display: flex;
    cursor: pointer;
}

.droppable-circle-board-member-cards-wrapper {
    position: relative;
    z-index: 0;
    height: 0;
    width: 0;
}

.droppable-circle-board-member-card-wrapper {
    position: absolute;
}

.droppable-circle-board-member-card-avatar-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.droppable-circle-board-inner-circle {
    z-index: 1;
    flex-shrink: 0;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.droppable-circle-board-inner-circle-name {
    font-weight: bold;
    font-size: large;
    color: #ffffff;
    margin-top: 30px;
    padding: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    text-align: center;
}

.droppable-circle-board-inner-circle-follow-number {
    font-size: large;
    color: #ffffff;
    margin-top: 20px;
}
