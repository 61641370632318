.admin-page {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-page-button {
    cursor: pointer;
    margin-bottom: 20px;
    color: #f0f0f0;
    background-color: #0c0c0c;
    padding: 4px;
    border-radius: 2px;
}

.admin-page-code {
    cursor: pointer;
}

/* Animation */

.shake-horizontal {
    -webkit-animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@-webkit-keyframes shake-horizontal {
    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px);
    }
    80% {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
    }
    90% {
        -webkit-transform: translateX(-8px);
        transform: translateX(-8px);
    }
}
