.home-page-wrapper {
    display: flex;
    width: 100%;
    background-color: #9dd0ff;
    background-image: url("../../img/favicon.png") ;
    background-size: 550px;
    min-height: 100%;
}

/* Hide scrollbar for IE, Edge and Firefox */
.home-page-wrapper {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.home-page-wrapper::-webkit-scrollbar {
    display: none;
}

.home-page-info-wrapper {
    width: 60%;
}

.container {
    width: 200px;
    height: 400px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(5px);
}

.welcome {
    font-size: 50px;
    margin-top: 35vh;
    margin-bottom: 70px;
    margin-left: 100px;
    animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.title {
    margin: 70px 0 70px 100px;
    font-size: 120px;
    font-weight: bold;
    animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1s both;
}

/* Form Container */
.home-page-form-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Mobile */
@media only screen and (max-width: 750px) {
    .home-page-wrapper {
        flex-direction: column;
        background-size: 250px;
        overflow:auto;
        padding-bottom: 30px;
    }

    .welcome {
        font-size: 25px;
        margin-top: 25vh;
        margin-bottom: 30px;
        margin-left: 10px;
    }

    .title {
        margin: 30px 0 25vh 10px;
        font-size: 50px;
        font-weight: bold;
    }

    .home-page-form-wrapper {
        flex: 0;
        width: 80%;
        margin: 0 auto;
    }

    .home-page-info-wrapper {
        width: 100%;
    }
}

/*Animation*/

@-webkit-keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
@keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}

