.fetched-preview {
    margin-bottom: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 10px;
    color: #3f3f3f;
    cursor: pointer;
}

.fetched-preview:hover {
    background-color: #f0f0f0;
}

.fetched-preview-with-image {
    border-top: 0;
    border-radius: 0 0 4px 4px;
}

.fetched-preview-image-container {
    border: 1px solid #e0e0e0;
    border-radius: 4px 4px 0 0;
    height: 200px;
    cursor: pointer;
}

.fetched-preview-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 4px 4px 0 0;
}

.fetched-preview-title {
    font-size: large;
    overflow-wrap: break-word;
    white-space: pre-line;
}

.fetched-preview-subtitle {
    margin-top: 10px;
    font-size: small;
    overflow-wrap: break-word;
    white-space: pre-line;
}

.fetched-preview-link {
    color: #56a5ff;
    text-decoration: none;
}
