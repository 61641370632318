.sign-up {
    padding: 40px 40px 40px 40px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(9px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign-up-title {
    margin-bottom: 24px;
}

.sign-up-input {
    margin-bottom: 12px;
    width: 240px;
    height: 3em;
    padding: 1em;
    border-width: 1px;
    border-radius: 4px;
    border-color: #86989B;
}

.sign-up-button {
    margin-bottom: 12px;
    background-color: #E05140;
    width: 240px;
    color: white;
    text-align: center;
    padding: 12px;
    border-radius: 4px;
    font-size: medium;
}

.sign-up-button-disabled {
    background-color: #727272;
}

.sign-up-button:hover {
    cursor: pointer;
}

.sign-up-button-disabled:hover {
    cursor: default;
}

.sign-in-message {
    cursor: default;
}

.sign-in-link {
    color: #E05140;
}
