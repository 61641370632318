/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

#root {
    height: 100%;
}

input[type="file"] {
    display: none;
}

.app-container {
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 750px) {
    .app-container {
        width: 100%;
        padding-bottom: calc(50px + env(safe-area-inset-bottom));;
    }
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    color: #0d71bb;
}
