.notification-container {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 2px 2px 1px 1px #e0e0e0;
    max-height: 40vh;
    flex-grow: 1;
    overflow-y: scroll;
}

.notification-header-wrapper {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    top: 0;
}

.notification-header {
    padding: 10px 5px;
    border-bottom: 3px solid #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notification-title {
    font-size: large;
}

.notification-count {
    padding: 1px 5px;
    border-radius: 4px;
    font-size: medium;
}

.notification-count-grey {
    background-color: #c7c7c7;
    color: #505050;
}

.notification-count-red {
    background-color: #E05140;
    color: white;
}

.notification-mark-all-button {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.notification-mark-all-button:hover {
    color: #0d71bb;
}

.notification-load-more {
    padding: 6px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    color: cornflowerblue;
}

.notification-load-more-disabled {
    color: lightgray;
    cursor: not-allowed;
}

.notification-load-more:hover {
    color: #0d71bb;
}

.notification-load-more-disabled:hover {
    color: lightgray;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.notification-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.notification-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Mobile */
/* Notifications will be shown as a page on mobile devices */
@media only screen and (max-width: 750px) {
    .notification-container {
        max-height: calc(100vh - 50px);
        overflow-y: scroll;
    }
}

