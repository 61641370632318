.settings-wrapper {
    display: flex;
    padding: 5em 2em 0 2em;
    flex-direction: column;
    align-items: center;
}

.settings-status {
    padding: 13px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 2px 2px 1px 1px #e0e0e0;
    margin-bottom: 20px;
    color: gray;
    width: 100%;
    max-width: 100%;
}

.settings-row {
    width: 60%;
    padding: 12px;
    border-radius: 2px;
}

.settings-row:hover {
    background-color: #e8e8e8;
    cursor: pointer;
}

.settings-row-header {
    font-size: 16px;
    font-weight: bold;
}

.settings-row-content {
    margin-top: 4px;
    color: #555555;
}

.settings-display-name {
    width: 240px;
    height: 3em;
    padding: 1em;
    border-width: 1px;
    border-radius: 4px;
    border-color: #86989B;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.settings-email {
    width: 240px;
    height: 3em;
    padding: 1em;
    border-width: 1px;
    border-radius: 4px;
    border-color: #86989B;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.settings-display-name-button-cancel {
    background-color: #727272;
}

.settings-display-name-button-confirm {
    background-color: #E05140;
}

.settings-email-button-cancel {
    background-color: #727272;
}

.settings-email-button-confirm {
    background-color: #E05140;
}

.settings-email-button-confirm-disabled {
    background-color: #727272;
    cursor: default;
}

.settings-rss-url {
    padding: 12px;
    background: #eeeded;
    font-family: monospace;
}

.settings-rss-code-checkboxes {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.settings-rss-code-checkboxes > * {
    margin-right: 10px;
}

/* Mobile */
@media only screen and (max-width: 750px) {
    .settings-wrapper {
        padding: 0;
    }

    .settings-row {
        min-width: 0;
        width: 100%;
    }
}
