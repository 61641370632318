/* TODO: all classnames not starting with post-new-comment are duplicated */
.post-new-comment-wrapper {
    margin-top: 20px;
    padding-top: 5px;
}

.post-new-comment-input-area {
    display: flex;
}

.post-new-comment-avatar {
    width: 35px;
    height: 35px;
    flex-shrink: 0;
}

.post-new-comment-input {
    border: none;
    flex: 1;
    resize: none;
    margin-left: 10px;
    margin-top: 5px;
    height: auto;
}

.post-new-comment-input:focus {
    outline: none !important;
}

.post-new-comment-attachment-button {
    top: 15px;
    right: 5px;
    width: 20px;
    cursor: pointer;
    z-index: 999;
}

.post-new-comment-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.post-new-comment-attachment-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    color: #727272;
    cursor: pointer;
}

.post-new-comment-post-button {
    padding: 5px 8px;
    font-weight: bold;
    font-size: small;
    background-color: #E05140;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.post-new-comment-post-button-invalid {
    background-color: #767676;
    cursor: none;
    pointer-events: none;
    user-select: none;
}

.fade-in {
    -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-7-25 20:27:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
