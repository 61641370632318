.new-post {
    overflow-y: scroll;
    max-height: 70vh;
}

@media only screen and (max-width: 750px) {
    .new-post {
        overflow-y: auto;
        max-height: calc(100vh - 50px);
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.new-post::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.new-post {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.new-post-avatar {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
}

.new-post-user-info {
    display: flex;
    align-items: center;
}

.new-post-name {
    margin: 0 10px;
    font-size: large;
    font-weight: bold;
}

/* Text Area */
.new-post-text-box-container {
    position: relative;
}

.new-post-text-box {
    margin-top: 10px;
    width: 100%;
    min-height: 20vh;
    max-height: 40vh;
    border-radius: 5px;
    border: 1px #e0e0e0 solid;
    resize: vertical;
    padding: 10px 20px 10px 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.new-post-text-box::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.new-post-text-box {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.new-post-text-box:focus {
    outline: none !important;
    border: 1px #b4b4b4 solid;
}

.new-post-attachment-buttons {
    display: flex;
    flex-direction: row;
}

.new-post-attachment-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #727272;
    margin-right: 10px;
}

.new-post-attachment-icon {
    width: 25px;
    height: 25px;
    margin-right: 2px;
}

.new-post-poll-button {
    position: absolute;
    top: 35px;
    right: 5px;
    width: 20px;
    cursor: pointer;
    z-index: 3;
}

.new-post-sharing-scope {
    display: flex;
    align-items: center;
}

.new-post-sharing-scope-dropdown {
    width: 100%;
}

.new-post-sharing-scope-question {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
    color: #555555;
}

.new-post-enable-resharing-question {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
    color: #555555;
}

.new-post-reshare-question {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    cursor: pointer;
    color: #555555;
}

.new-post-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

.new-post-post-btn {
    padding: 10px 20px;
    font-weight: bold;
    font-size: medium;
    background-color: #E05140;
    color: white;
    border-radius: 5px;
    width: auto;
    cursor: pointer;
}

.new-post-post-btn-disabled {
    background-color: #767676;
    cursor: not-allowed;
}

/* Reshare Preview */
.new-post-reshared-info-wrapper {
    display: flex;
}

.new-post-reshared-info {
    display: flex;
    flex-grow: 1;
    align-items: center;
    margin-bottom: 5px;
}

.new-post-reshare-delete {
    font-size: x-large;
    cursor: pointer;
}

.new-post-reshare-preview {
    border: #f0f0f0 solid 1px;
    background-color: #f0f0f0;
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
}

.new-post-resharable {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.new-post-reshare-content-summary {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.new-post-reshare-controls {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 750px) {
    .new-post-avatar {
        width: 30px;
        height: 30px;
    }

    .new-post-text-box {
        resize: none;
    }
}
