.mobile-all-read-button {
    position: fixed;
    bottom: calc(60px + env(safe-area-inset-bottom));
    right: 10px;
    width: 60px;
    height: 60px;
    background-color: #E05140;
    border-radius: 50%;
    padding: 15px;
    color: white;
}
