.nav-bar-container {
    position: fixed;
    background-color: #0c0c0c;
    width: 100%;
    display: flex;
    z-index: 3;
    cursor: pointer;
}

.nav-bar-top {
    top: 0;
}

.nav-bar-bottom {
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
}

.nav-bar-button-container {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.nav-bar-button-container-spaced {
    width: 25%;
    justify-content: center;
}

.nav-bar-button-container-aligned {
    padding-left: 20px;
    padding-right: 20px;
}

.nav-bar-button-active {
    background-color: #262626;
    border-radius: 5px;
}

.nav-bar-button-container>svg {
    width: 22px;
    height: 40px;
    color: white;
}

.nav-bar-button-text {
    margin-left: 6px;
    color: #ffffff;
}

.nav-bar-name-and-avatar {
    margin-left: auto;
    margin-right: 10px;
}

.nav-bar-avatar {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

.mobile-nav-bar-avatar {
    width: 30px;
    height: 30px;
}

.nav-bar-notification-indicator-wrapper {
    position: relative;
    width: 0;
    height: 0;
    bottom: 14px;
    left: 24px;
}

.nav-bar-notification-indicator {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ff6969;
    right: 0;
    top: 0;
}
