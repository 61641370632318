.post-nested-comment {
    display: flex;
    margin-top: 3px;
}

.highlight-comment {
    background: #f0f0f0;
    border: 2px solid #f0f0f0;
    border-radius: 2px;
}

.post-nested-comment-avatar {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
}

.post-nested-comment-name {
    font-size: small;
    font-weight: bold;
    margin-left: 3px;
}

.post-nested-comment-reply-to {
    height: 15px;
    width: 15px;
    margin-top: 2px;
    color: #777777;
    cursor: pointer;
}

.post-nested-comment-content {
    overflow-wrap: break-word;
    flex: 1;
    min-width: 0;
    margin-left: 3px;
    white-space: pre-line;
}

.post-nested-comment-actions {
    display: flex;
    align-items: center;
}

.post-nested-comment-time {
    font-size: smaller;
    color: #767676;
}

.post-nested-comment-reply-btn {
    margin-left: 5px;
    font-size: x-small;
    background-color: #f0f0f0;
    padding: 1px 2px;
    border-radius: 2px;
    font-weight: bold;
    cursor: pointer;
}

.post-nested-comment-reply-btn:hover {
    background-color: #e7e7e7;
}

.post-nested-comment-more-actions-trigger {
    color: #bdbdbd;
    width: 17px;
    height: 17px;
    border-radius: 2px;
    padding: 2px;
    cursor: pointer;
    margin-left: 5px;
}

.post-nested-comment-more-actions-trigger:hover {
    background-color: #f0f0f0;
}

