.home-wrapper {
    display: flex;
    padding: 5em 2em 0 2em;
}

.home-posts-wrapper {
    width: 60%;
    max-width: 60%;
}

@media only screen and (max-width: 3350px) {
    .home-posts-wrapper-multi {
        width: 80%;
        max-width: 80%;
    }
}

@media only screen and (max-width: 2450px) {
    .home-posts-wrapper-multi {
        width: 75%;
        max-width: 75%;
    }
}

@media only screen and (max-width: 1650px) {
    .home-posts-wrapper-multi {
        width: 66%;
        max-width: 66%;
    }
}

@media only screen and (max-width: 950px) {
    .home-posts-wrapper-multi {
        width: 60%;
        max-width: 60%;
    }
}

.home-posts-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px; /* gutter size offset */
    width: auto;
}

.home-posts-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
}

.home-right-column-container {
    margin-left: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: 5em;
    height: 90vh;
    display: flex;
    flex-direction: column;
    width: 40%;
    max-width: 40%;
}

@media only screen and (max-width: 3350px) {
    .home-right-column-container-multi {
        width: 20%;
        max-width: 20%;
    }
}

@media only screen and (max-width: 2450px) {
    .home-right-column-container-multi {
        width: 25%;
        max-width: 25%;
    }
}

@media only screen and (max-width: 1650px) {
    .home-right-column-container-multi {
        width: 33%;
        max-width: 33%;
    }
}

@media only screen and (max-width: 950px) {
    .home-right-column-container-multi {
        width: 40%;
        max-width: 40%;
    }
}

.home-right-column-new-post-wrapper {
    padding: 13px;
    background-color: #ffffff;
    width: 100%;
    max-width: 700px;
    border-radius: 5px;
    box-shadow: 2px 2px 1px 1px #e0e0e0;
    resize: none;
    margin-bottom: 10px;
    max-height: 70vh;
}

.home-status {
    padding: 13px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 2px 2px 1px 1px #e0e0e0;
    margin-bottom: 20px;
    color: gray;
    width: 100%;
    max-width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.home-posts-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.home-posts-wrapper {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@media only screen and (max-width: 750px) {
    .home-wrapper {
        padding: 0;
    }

    .post-wrapper {
        width: 100%;
    }

    .home-posts-wrapper {
        width: 100%;
        max-width: 100%;
        margin-bottom: 50px;
    }
}

.home-load-more {
    padding: 13px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 2px 2px 1px 1px #e0e0e0;
    margin-bottom: 20px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    color: cornflowerblue;
}

.home-load-more-disabled {
    color: lightgray;
    cursor: not-allowed;
}

.home-load-more:hover {
    color: #0d71bb;
}

.home-load-more-disabled:hover {
    color: lightgray;
}

.mobile-new-post-button {
    position: fixed;
    bottom: calc(60px + env(safe-area-inset-bottom));
    right: 10px;
    width: 60px;
    height: 60px;
    background-color: #E05140;
    border-radius: 50%;
    padding: 15px;
    color: white;
}

.mobile-new-post-button:hover {
    cursor: pointer;
}
