.desktop-users-wrapper {
    display: flex;
    padding: 5em 2em 0 2em;
}

.desktop-users-status {
    padding: 13px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 2px 2px 1px 1px #e0e0e0;
    margin-bottom: 20px;
    color: gray;
    width: 100%;
    max-width: 100%;
}

.desktop-users-user-cards-container {
    padding: 0 50px;
    height: 550px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: grid;
    position: absolute;
    top: 5em;
    width: 100%;
    left: 0;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    grid-column-gap: 30px;
    grid-row-gap: 30px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.desktop-users-user-cards-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.desktop-users-user-cards-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.desktop-users-boards-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    direction:ltr;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.desktop-users-boards {
    height: 100%;
    display: flex;
}
