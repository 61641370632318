.post-reactions-wrapper {
    display: flex;
    max-width: 80%;
    flex-wrap: wrap;
}

.post-reaction {
    padding: 2px 8px;
    margin-right: 5px;
    margin-top: 3px;
    border-radius: 4px;
    max-height: 25px;
    background-color: #f0f0f0;
    color: #767676;

    font-weight: bold;
    font-size: small;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.post-reaction-icon {
    width: 25px;
    height: 25px;
    padding: 5px
}

.post-reaction:hover {
    background-color: #e7e7e7;
}

.post-reaction-loading {
    cursor: default;
    background-color: #fafafa;
}

.post-reaction-loading:hover {
    background-color: #fafafa;
}

.post-reaction-active {
    background-color: #e0f4ff;
    border: #9dd0ff 1px solid;
}

.post-reaction-active:hover {
    background-color: #e0f4ff;
}

.post-emoji {
    font-size: large;
    font-weight: 400;
}

#post-reaction-emoji-picker-wrapper {
    position: relative;
    width: 0;
    height: 0;
    z-index: 3;
}

.post-reaction-emoji-picker {
    position: absolute;
    top: 10px;
}

.post-reaction-emoji-picker-mobile {
    width: 100%
}

.post-reactions-detail-emoji {
    font-size: 1.2em;
    margin-bottom: 8px;
    padding: 2px;
    border-bottom: 2px #f0f0f0 solid;
}

.post-reactions-detail-author-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.post-reactions-detail-author-avatar {
    width: 30px;
    height: 30px;
    align-self: flex-start;
    flex-shrink: 0;
}

.post-reactions-detail-author {
    padding-left: 3px;
    width: 100%;
    overflow-wrap: break-word;
    word-break: break-word;
    color: #0d71bb;
    font-weight: bold;
}
