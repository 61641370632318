.pill-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pill-form > * {
    margin-bottom: 15px;
}
