.settings-profile-pic-preview {
    width: 100%;
    height: 250px;
    background-size: 100px;
    position: relative;
}

.settings-profile-pic-selections {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.settings-profile-selection-option {
    margin-right: 20px;
    margin-top: 15px;
    width: 50px;
    height: 50px;
    border: 2px solid #e0e0e0;
    background-color: #e0e0e0;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    cursor: pointer;
}

.settings-profile-selection-option-selected {
    border: 2px solid #9dd0ff;
    background-color: #e0e0e0;
}

.settings-profile-selection-option-img {
    max-width: 100%;
    max-height: 100%;
}
