.post-attachments-modal-content-wrapper {
    padding: 10px;
    overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.post-attachments-modal-content-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.post-attachments-modal-content-wrapper {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
