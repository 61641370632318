.notification-wrapper {
    padding: 5px;
    border-bottom: 2px solid #f1f1f1;
}

.notification-first-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.notification-info {
    display: flex;
    align-items: center;
}

.notification-second-row {
    margin-left: 35px;
    color: #767676;
    cursor: pointer;
    margin-top: 5px;
    overflow-wrap: break-word;
    white-space: pre-line;
}

.notification-avatar {
    width: 30px;
    height: 30px;
    align-self: flex-start;
    flex-shrink: 0;
}

.notification-notifier {
    max-width: 90%;
    padding-left: 3px;
    overflow-wrap: break-word;
}

.notification-notifier-wrapper {
    width: 100%;
    word-break: break-word;
}

.notification-notifier-id {
    color: #0d71bb;
}

.notification-time {
    color: #767676;
    align-self: flex-start;
}

.notification-summary {
    display: inline;
    inline-size: 100%;
    color: #767676;
    overflow-wrap: break-word;
    white-space: pre-line;
}

.notification-status {
    padding: 6px;
    width: 100%;
    text-align: center;
    color: lightgray;
}

/* Mobile */
@media only screen and (max-width: 750px) {
    .notification-wrapper {
        padding: 15px;
    }

    .notification-avatar {
        width: 40px;
        height: 40px;
    }

    .notification-first-row>*{
        font-size: larger;
    }

    .notification-notifier{
        padding-left: 10px;
    }

    .notification-second-row {
        margin-left: 50px;
    }
}

