.mobile-users-wrapper {
    display: flex;
    padding: 0;
}

.mobile-users-status {
    padding: 13px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 2px 2px 1px 1px #e0e0e0;
    margin-bottom: 20px;
    color: gray;
    width: 100%;
    max-width: 100%;
}

.mobile-users-grid-container {
    display: grid;
    width: 100%;
    padding: 15px;
    top: 0;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-column-gap: 5px;
    grid-row-gap: 5px;
}

.mobile-users-user-card-wrapper {
    background-color: #ffffff;
    width: 100%;
    border-radius: 5px;
    box-shadow: 2px 2px 1px 1px #e0e0e0;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 60px;
}

.mobile-users-user-card-right {
    display: flex;
    flex: 1;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
}

.mobile-users-user-card-avatar {
    flex-shrink: 0;
    height: 60px;
    width: 60px;
}

.mobile-users-user-card-avatar-img {
    width: 100%;
    height: 100%;
}

.mobile-users-user-card-name {
    font-weight: bold;
    font-size: large;
    margin-left: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 10em;
}

.mobile-users-user-card-buttons {
    display: flex;
    flex-direction: row;
}

.mobile-users-user-card-button {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.mobile-users-user-card-button-disabled {
    color: lightgray;
    cursor: not-allowed;
}
