.circles-wrapper {
    display: flex;
    padding: 0;
}

.circles-status {
    padding: 13px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 2px 2px 1px 1px #e0e0e0;
    margin-bottom: 20px;
    color: gray;
    width: 100%;
    max-width: 100%;
}

.circles-grid-container {
    display: grid;
    width: 100%;
    padding: 15px;
    top: 0;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-column-gap: 5px;
    grid-row-gap: 5px;
}

.circles-circle-card-wrapper {
    background-color: #ffffff;
    width: 100%;
    border-radius: 5px;
    box-shadow: 2px 2px 1px 1px #e0e0e0;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 60px;
    justify-content: space-between;
}

.circles-circle-card-name {
    font-weight: bold;
    font-size: large;
    margin-left: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.circles-circle-card-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.circles-circle-card-count-icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    color: #727272;
}

.circles-circle-card-count {
    margin-right: 15px;
    color: #727272;
}

.circles-circle-card-button {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    color: #565656;
}

.circles-create-circle-button {
    position: fixed;
    bottom: calc(60px + env(safe-area-inset-bottom));
    right: 10px;
    width: 60px;
    height: 60px;
    background-color: #E05140;
    border-radius: 50%;
    padding: 15px;
    color: white;
}

.circles-create-circle-button:hover {
    cursor: pointer;
}
