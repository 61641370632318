.add-new-circle-name-input {
    width: 240px;
    height: 3em;
    padding: 1em;
    border-width: 1px;
    border-radius: 4px;
    border-color: #86989B;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.modal-content-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.modal-content-button {
    padding: 8px 10px;
    font-weight: bold;
    font-size: medium;
    color: white;
    border-radius: 5px;
    width: auto;
    cursor: pointer;
    margin-right: 20px;
}

.confirm {
    background-color: #E05140;
}

.cancel {
    background-color: #767676;
    margin-right: 30px;
}
