.toast {
    margin-right: 16px;
    margin-top: 16px;
    width: 200px;
    position: relative;
    padding: 16px;
    border-radius: 5px;
    background: #333333;
    box-shadow: 2px 2px 1px 1px rgba(42, 42, 42, 1);
    color: white;
}

@media only screen and (max-width: 750px) {
    .toast {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}
