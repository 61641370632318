.draggable-user-card-wrapper {
    background-color: #ffffff;
    width: 100%;
    height: 100px;
    border-radius: 5px;
    box-shadow: 2px 2px 1px 1px #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.draggable-user-card-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.draggable-user-card-avatar {
    height: 100px;
    width: 100px;
    flex-shrink: 0;
}

.draggable-user-card-avatar-img {
    width: 100%;
    height: 100%;
}

.draggable-user-card-name {
    font-weight: bold;
    font-size: large;
    margin-left: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
}

.draggable-user-card-buttons {
    display: flex;
    flex-direction: row;
}

.draggable-user-card-button {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.draggable-user-card-button-disabled {
    color: lightgray;
    cursor: not-allowed;
}
