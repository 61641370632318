/* https://github.com/tjallingt/react-youtube/issues/242#issuecomment-623885531 */
.link-preview-youtube-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 15px;
    border-radius: 5px;
}

.link-preview-youtube-container iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
