.media-v2-collage-item {
    cursor: pointer;
    object-fit: cover;
    width: 100%;
    border-radius: 4px;
}

.media-v2-collage-pswp-content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.media-v2-collage-pswp-image {
    width: auto;
}

@media only screen and (max-width: 750px) {
    .media-v2-collage-pswp-content {
        position: absolute;
        top: 50%;
        left: auto;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .media-v2-collage-pswp-image {
        width: 100%;
    }
}
