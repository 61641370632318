.pill-dropdown-menu-container {
    position: relative;
}

.pill-dropdown-children {
    display: inline-block;
}

.pill-dropdown-menu {
    border-radius: 4px;
    position: absolute;
    border: 1px solid #e0e0e0;
    right: 0;
    width: 150px;
    opacity: 0;
    visibility: hidden;
}

.pill-dropdown-menu-item {
    padding: 6px;
    background-color: white;
    cursor: pointer;
    font-weight: bold;
    border-radius: 4px;
    color: #c97070;
}

.pill-dropdown-menu-item:hover {
    background-color: #f5f4f4;
}

.pill-dropdown-menu-active {
    opacity: 1;
    visibility: visible;
}
