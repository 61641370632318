.post-wrapper-page {
    display: flex;
    padding: 5em 50px 0 50px;
}

.post-status {
    padding: 13px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 2px 2px 1px 1px #e0e0e0;
    margin-bottom: 20px;
    color: gray;
    width: 100%;
}

@media only screen and (max-width: 750px) {
    .post-wrapper-page {
        padding: 0 0 50px 0;
    }
}
